<template>
  <ul class="column_list">
    <slot />
  </ul>
</template>
<script setup lang="ts">
</script>

<style modules>
.column_list {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.column_list.ty2 {
  gap: 16px;
}

.column_list.ty3 {
  gap: 0px;
}

.column_list.ty3 > li {
  padding:16px 0; border-bottom:1px solid var(--color-line);
}

.column_list.ty3 > li:first-child {
    padding-top: 0;
  }

.column_list.ty4 {
  gap: 0px;
}

@media screen and (max-width: 768px) {
  .column_list,
  .column_list.ty4 {
    gap: 0px;
  }

  .column_list>li {
    margin-bottom: 0;
    padding: 16px 0;
    border-bottom: 1px solid var(--color-line);
  }

  .column_list.ty2>li {
    border-bottom: none;
  }

  .column_list.ty4>li {
    padding: 0;
    border-bottom: none;
  }
}
</style>
