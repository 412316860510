<template>
  <client-only>
    <skeleton-box v-if="!imageLoaded" height="160px" width="282px" aspect-ratio="141/80" />
    <img v-show="imageLoaded" :src="imageUrl" :width="width" @load="imageLoaded = true">
  </client-only>
</template>
<script setup lang="ts">
defineProps<{
  imageUrl: string;
  width: number;
}>()
const imageLoaded = ref<boolean>(false)
</script>
<style scoped>
  img { object-fit: cover; width: 100%; height: 100%; }
  </style>
